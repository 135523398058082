<template>
  <div class="bookingBlock__content">
    <div class="bookingBlock__content-inner">
      <vue-scroll :ops="ops" class="aircraftBooking js-customScroll">
        <div class="aircraftBooking__content">
          <div class="aircraftBooking__title">Aircraft</div>
          <AircraftDetail
            :category="'Aircraft'"
            :detailsLeft="detailsLeft"
            :detailsRight="detailsRight"
            :aircraftImages="aircraftImages"
            :title="$store.state.bookingFromSearch.aircraft.aircraftModel"
          />
        </div>
      </vue-scroll>
      <router-link class="bookingBlock__content-prev js-bookingNav-item" to="flights" style="padding-top: 27%">
          <span class="icon">
            <SvgIcon name="left-arrow"/>
          </span>
        <span class="text">Back</span>
      </router-link>
      <router-link class="bookingBlock__content-next js-bookingNav-item" to="travelers" style="padding-top: 27%">
          <span class="icon">
            <SvgIcon name="right-arrow"/>
          </span>
        <span class="text">Next</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import './Aircraft.styl'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import AircraftDetail from '@/components/AircraftDetail/AircraftDetail'

  export default {
    name: 'aircraft',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      detailsLeft () {
        const data = this.$store.state.bookingFromSearch.aircraft
        // console.log(data);
        return [
          {icon: 'aeroplane', title: ' Aircraft Type:', value: data.aircraftType},
          {icon: 'protection', title: 'Argus Safety Rating:', value: ((data.argusSafetyRating === undefined || data.argusSafetyRating === null || data.argusSafetyRating === '') && data.bookableDemo === true) ? 'Demo' : data.argusSafetyRating},
          {icon: 'placeholder2', title: 'Homebase:', value: data.homeBase},
          {icon: 'clock2', title: 'Year of Manufacture:', value: data.yearOfManufacture},
          {icon: 'clock', title: 'Last Interior Refurbish:', value: data.lastInteriorRefurbish},
          {icon: 'clock', title: 'Last Exterior Refurbish:', value: data.lastExteriorRefurbish},
          {icon: 'tv', title: 'Television Onboard:', value: data.numberOfTelevision ? data.numberOfTelevision : ''},
        ]
      },
      detailsRight () {
        const data = this.$store.state.bookingFromSearch.aircraft
        return [
          {icon: 'moving', title: 'Max Passengers:', value: data.maxPassengers},
          {icon: 'open-folder', title: 'Insurance:', value: ''},
          {icon: 'chronometer', title: 'Hours Flown:', value: data.hoursFlown},
          {icon: 'speedometer', title: 'Speed:', value: data.aircraftSpeed},
          {icon: 'mountains', title: 'Range:', value: data.aircraftRange},
          {icon: 'wifi', title: 'Wi-Fi:', value: data.wifiOnboard ? 'Yes' : 'No'},
          {icon: 'box', title: 'Cargo Capacity:', value: data.cargoCapability},
        ]
      },
      aircraftImages () {
        return this.$store.state.bookingFromSearch.aircraft.aircraftImages
      },
    },
    components: {
      SvgIcon,
      AircraftDetail,
    },
  }
</script>
